<template>
    <div class="container is-max-widescreen site-header">
        <div class="level is-mobile">
            <img v-if="global.mode.header != ''" src="../../src/assets/logo.png">
            <a v-if="global.mode.header == ''" href="/" class="level-left">
                <img src="../../src/assets/logo.png">
            </a>
            <div v-if="global.mode.header == ''" class="level-right nav-main">
                <div class="nav-secondary">
                    <router-link v-if="global.content.pages.hasOwnProperty('login')" :to="global.content.pages.login.path">
                        {{ global.content.pages.login.name }}
                    </router-link>
                    <router-link v-if="global.content.pages.hasOwnProperty('createLogin')" :to="global.content.pages.createLogin.path">
                        {{ global.content.pages.createLogin.name }}
                    </router-link>
                </div>
                <div class="nav-primary">
                    <router-link v-if="global.content.pages.hasOwnProperty('findConsultant')" :to="global.content.pages.findConsultant.path">
                        {{ global.content.pages.findConsultant.name }}
                    </router-link>
                    <router-link v-if="global.content.pages.hasOwnProperty('becomeConsultant')" :to="global.content.pages.becomeConsultant.path">
                        {{ global.content.pages.becomeConsultant.name }}
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import { useComposition } from '@/compositions/composition'

export default defineComponent({
    setup() {
        const { global } = useComposition();

        return {
            global
        }
    },
})
</script>
<template>
    <div class="container is-max-widescreen site-footer">
        <div class="columns">
            <div class="column">Footer (kontaktinfo og diverse links)</div>
            <!--
            <input v-model="message" placeholder="edit me">
            <p>Message is: {{ message }}</p>
            -->
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import { useComposition } from '@/compositions/composition'

export default defineComponent({
  setup() {
    const { message } = useComposition();

    return {
      message
    }
  },
})
</script>